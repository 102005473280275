<script setup lang="ts">
import { useDynamicCell } from '../../composables'

defineProps<{
  component: string
  data: any
  visible: boolean
}>()

// DYNAMIC COMPONENTS
const { getActionComponent } = useDynamicCell()!
</script>

<template>
  <Suspense>
    <VTooltip placement="top" :distance="10">
      <div class="p-2">
        <div class="pointer-events-none size-1 rounded-full bg-red-500" />
      </div>

      <div
        class="absolute left-0 top-0 -z-10 h-full w-full cursor-help hover:bg-red-500/5"
      />

      <template #popper>
        <component :is="getActionComponent(component)" v-bind="{ data }" />
      </template>
    </VTooltip>
  </Suspense>
</template>

<style scoped lang="postcss">
:deep(.v-popper) {
  .v-popper__inner {
    @apply !shadow-none;
  }
}
</style>
